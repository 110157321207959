<template>
  <div class="quotes-skeleton-wrap d-flex flex-wrap">
    <div
      v-for="item in items"
      :key="item + 1"
      :style="{ width: itemsWidth }"
      class="quote-item-skeleten p-4"
      :class="itemClass"
    >
      <skeleton-screen width="95%" class="mb-3" />
      <skeleton-screen width="100%" class="mb-3" />
      <skeleton-screen width="97%" class="mb-3" />
      <skeleton-screen width="95%" class="mb-3" />
      <skeleton-screen width="100%" class="mb-3" />
      <skeleton-screen width="97%" class="mb-3" />
      <skeleton-screen width="100%" class="mb-3" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'quote-skeleton',

  props: {
    itemsWidth: {
      type: String,
      default: '349px',
    },
    itemClass: {
      type: [String, Object, Array],
      default: '',
    },
    itemCount: {
      type: Number,
      default: 9,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss">
.quotes-skeleton-wrap {
  margin-right: -25px;
}
.quote-item-skeleten {
  margin-right: 15px;
  height: 300px;
}
</style>
