<template>
  <b-row no-gutters>
    <VideosSidebar />

    <b-col md="10">
      <div class="py-4 mx-4 mt-3">
        <div class="w-50 mx-auto mb-3 discover__menu">
          <h4 class="mb-3 mt-0 text-center discover__menu-title">Find Video by Keyword</h4>
          <div class="discover__menu-search">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="search-addon1" @click="performSearch">
                  <img src="@/assets/icons/new/search.svg" alt="search icon" />
                </span>
              </div>
              <input
                type="text"
                class="form-control h-100"
                placeholder="Type a video keyword and hit enter"
                aria-label="search"
                aria-describedby="search-addon1"
                v-model="searchInput"
                @keyup.enter="performSearch"
              />
            </div>
            <div>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="clear"
                  class="discover__menu-advanced-toggle youtube"
                  v-b-tooltip.hover
                  title="Youtube"
                  :class="{ active: platform === 'youtube' }"
                  @click="setActivePlatform('youtube')"
                >
                  <img src="@/assets/icons/new/youtube.svg" alt="search icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Daily Motion"
                  variant="clear"
                  class="discover__menu-advanced-toggle"
                  :class="{ active: platform === 'dailymotion' }"
                  @click="setActivePlatform('dailymotion')"
                >
                  <img src="@/assets/icons/new/dailymotion.svg" alt="search icon" />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div class="videos-main">
          <skeleton-screens
            v-if="!loadingMore && $apollo.queries.videoSearch.loading"
            class="d-flex justify-content-between flex-wrap"
            itemClass="shadow-sm mb-4"
            width="250px"
            height="250px"
            times="12"
          />
          <template v-else-if="videos.length">
            <div class="article-results-label">
              <span class="content">{{ capitalize(platform) }}</span>
            </div>
            <h5 class="font-weight-bold"></h5>

            <div class="videos-wrap">
              <div
                class="video-card shadow-sm mb-4"
                v-for="(video, i) in videos"
                :key="`${video.id}-${i}`"
                @click="viewVideo(video)"
              >
                <lazy-background-image :src="video.thumb" class="video-card__img" />
                <div class="video-card__body">
                  <h5 class="video-card__title text-truncate" :title="video.title">{{ video.title }}</h5>
                  <div class="video-card__summary" v-html="video.description"></div>
                </div>
                <div v-if="platform != 'youtube'" class="video-card__footer">
                  <div class="d-flex text-center justify-content-between">
                    <div class="w-100">
                      <div class="font-weight-bold">{{ numFormat(video.viewCount) }}</div>
                      <div class="text-muted small">Views</div>
                    </div>
                    <div class="w-100">
                      <div class="font-weight-bold">{{ numFormat(video.commentCount) }}</div>
                      <div class="text-muted small">Comments</div>
                    </div>
                    <div class="w-100">
                      <div class="font-weight-bold">{{ numFormat(video.likeCount) }}</div>
                      <div class="text-muted small">Likes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-else-if="videos.length < 1 && !$apollo.queries.videoSearch.loading"
            class="bg-white text-center shadow-sm rounded-sm p-5"
          >
            <img class="pt-3" width="300" src="@/assets/images/blank_canvas.svg" alt="" />
            <h3 class="m-5 text-muted">No results found.</h3>
          </div>
          <div v-if="loadingMore" class="text-center mt-4"><spinner size="3" /></div>
        </div>
      </div>
    </b-col>
    <b-modal
      id="ViewVideoModal"
      size="md"
      body-class="p-0"
      modal-class="view-video-modal"
      content-class="border-0 overflow-hidden rounded"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <div v-if="loadingDetails" class="p-5 m-5 text-center"><spinner /></div>
        <template v-else>
          <div v-html="activeVideo.embedHtml" class="video-embed-wrap"></div>
          <div class="modal-video-details py-2 px-3">
            <div class="d-flex justify-content-between">
              <h5 class="w-50 text-truncate" :title="activeVideo.title">{{ activeVideo.title }}</h5>
              <div class="modal-video-stats d-flex">
                <div class="w-100 mr-3">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.viewCount) }}</div>
                  <div class="text-muted small">Views</div>
                </div>
                <div class="w-100 mr-3">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.commentCount) }}</div>
                  <div class="text-muted small">Comments</div>
                </div>
                <div class="w-100">
                  <div class="font-weight-bold">{{ numFormat(activeVideo.likeCount) }}</div>
                  <div class="text-muted small">Likes</div>
                </div>
              </div>
            </div>

            <div class="text-right mt-3 mb-2 mr-2">
              <b-dropdown class="custom-dropdown" size="sm" no-caret dropup right no-flip>
                <template slot="button-content">
                  <svg-icon name="share" class="mr-2" />
                  Share
                  <svg-icon name="chevron-down" class="ml-2 mt-0" />
                </template>
                <div class="py-2">
                  <b-dropdown-item @click="handleShareClick('social')">
                    <svg-icon name="network" class="mr-1" />
                    Social Post
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleShareClick('blog')">
                    <svg-icon name="wordpress" class="mr-1" />
                    Blog Post
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
          </div>
        </template>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import { capitalize } from 'lodash';
import { numFormat } from '~/utils/helpers';
import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';
import LazyBackgroundImage from '~/components/LazyBackgroundImage';

import app from '~/main';
import VideosSidebar from '~/components/Videos/VideosSidebar';

import { VIDEO_SEARCH_QUERY, YOUTUBE_VIDEO_DETAILS_QUERY, CURATED_TOPICS_QUERY } from '~/graphql/queries';

export default {
  components: {
    VideosSidebar,
    LazyBackgroundImage,
  },

  mixins: [InfiniteScrollMixin],

  async beforeRouteEnter(to, from, next) {
    try {
      await app.$apollo
        .query({
          query: CURATED_TOPICS_QUERY,
        })
        .then(({ data }) => {
          app.$store.dispatch('topics/updateCurated', data.curatedTopics);
        });

      next();
    } catch (e) {
      next();
    }
  },

  data() {
    return {
      searchInput: this.$route.query.q,
      searchKeyword: this.$route.query.q,
      activeVideo: {},
      loadingMore: false,
      videoSearch: {},
      nextPageToken: null,
      pageCounter: 1,
      loadingDetails: false,
    };
  },

  computed: {
    platform() {
      return this.$route.query.platform || 'youtube';
    },

    videos() {
      return this.videoSearch.videos && this.videoSearch.videos.length ? this.videoSearch.videos : [];
    },

    canSubmit() {
      // const empties = this.filters.filter(filter => filter.value === '');
      // return empties.length < 1 && this.viewcount != '';
      return true;
    },
  },

  apollo: {
    videoSearch: {
      query: VIDEO_SEARCH_QUERY,
      variables() {
        return {
          limit: 20,
          page: 1,
          keywords: [this.searchKeyword],
          source: this.platform,
          nextPageToken: '',
        };
      },
      update(data) {
        if (data.videoSearch) {
          this.nextPageToken = data.videoSearch.nextPageToken;
          return data.videoSearch;
        }
        return {};
      },
      error(e) {
        console.log(e);
        this.$notify({
          group: 'main',
          type: 'native-error',
          title: 'An error occurred',
          text: 'An error occurred while loading videos',
        });

        return true;
      },
    },
  },

  watch: {
    isRockBottom(isRockBottom) {
      if (
        !this.loadingMore &&
        !this.$apollo.queries.videoSearch.loading &&
        isRockBottom &&
        this.videos.length &&
        this.loadMoreEnabled
      ) {
        this.loadMore();
      }
    },

    $route(to) {
      if (to.query.q) {
        this.searchInput = to.query.q;
        this.searchKeyword = to.query.q;
      } else {
        this.searchInput = '';
        this.searchKeyword = '';
      }
    },
  },

  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.isRockBottom = this.rockBottom();
    },

    setActivePlatform(platform) {
      this.$router.push({ query: { q: this.$route.query.q, platform } }, () => {});
    },

    async loadMore() {
      this.loadingMore = true;

      try {
        let variables;
        if (this.platform == 'youtube') {
          variables = {
            nextPageToken: this.nextPageToken,
          };
        } else {
          variables = { page: ++this.pageCounter };
        }

        // Fetch more data and transform the original result
        await this.$apollo.queries.videoSearch.fetchMore({
          // New variables
          variables,
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newVideos = fetchMoreResult.videoSearch.videos;
            const hasMore = fetchMoreResult.videoSearch.hasMore;
            const nextPageToken = fetchMoreResult.videoSearch.nextPageToken;
            const page = fetchMoreResult.videoSearch.page;
            const total = fetchMoreResult.videoSearch.total;

            this.loadMoreEnabled = hasMore;

            return {
              videoSearch: {
                __typename: previousResult.videoSearch.__typename,
                // Merging the tag list
                videos: [...previousResult.videoSearch.videos, ...newVideos],
                page,
                total,
                hasMore,
                nextPageToken,
              },
            };
          },
        });
      } catch (e) {
        console.log(e.message);
      }

      this.loadingMore = false;
    },

    performSearch() {
      this.searchKeyword = this.searchInput;
      this.$router.push({ query: { q: this.searchInput, platform: this.platform } }, () => {});
    },

    viewVideo(video) {
      if (this.platform === 'youtube') {
        this.loadingDetails = true;
        this.$bvModal.show('ViewVideoModal');

        this.$apollo
          .query({
            query: YOUTUBE_VIDEO_DETAILS_QUERY,
            variables: {
              id: video.id,
            },
          })
          .then(({ data }) => {
            this.loadingDetails = false;

            this.activeVideo = data.youtubeVideoDetails;
          })
          .catch(() => {
            this.$bvModal.hide('ViewVideoModal');

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'Unable to load video. Please try again later or try another video.',
            });
          });
      } else {
        this.activeVideo = video;

        this.$bvModal.show('ViewVideoModal');
      }
    },

    async handleShareClick(type) {
      this.$bvModal.hide('ViewVideoModal');

      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: `${this.activeVideo.title} - <a href="${this.activeVideo.url}">${this.activeVideo.url}</a>`,
          postLink: this.activeVideo.url,
        });

        this.$store.dispatch('createPost/fetchLinkPreview', this.activeVideo.url);
      } else if (type === 'blog') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: `
            <p style="text-align: center">${this.activeVideo.embedHtml}</p><br>
            <h3>${this.activeVideo.title}</h3>
            <p>${this.activeVideo.description}</p>
          `,
          title: this.activeVideo.title,
          postLink: this.activeVideo.url,
        });
      }

      this.$router.push({ name: 'compose', params: { type, id: 0 } });
    },

    numFormat,
    capitalize,
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.discover__menu {
  &-title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;

    .input-group {
      color: $primary !important;
      border-radius: 0.3rem !important;
      background-color: #ffffff !important;
      input {
        font-size: 0.9rem;
        height: 40px;
        &::placeholder {
          color: $gray-500;
        }
      }
    }
    input,
    .input-group-prepend,
    .input-group-text {
      // width: 1.8rem;
      background-color: transparent;
      border: none;
      outline: none;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
  &-advanced-toggle {
    background-color: #d8f3dc;
    border-radius: 0.3rem;
    border: 1px solid #52b788;
    height: 40px;
    width: 56px;
    margin-top: -18px;
    margin-left: 10px;
    text-align: center;

    &:focus {
      box-shadow: none;
    }

    .icon {
      width: 0.8rem;
      height: 0.9rem;
    }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

.article-filters-wrap {
  background-color: $white;
  position: relative;

  &:after,
  &:before {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
    z-index: -1;
  }
  &:before {
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid $gray-200;
    left: 23px;
    top: -10px;
  }
  &:after {
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    left: 24px;
    top: -9px;
  }

  .article-filter-row {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.article-results-label {
  color: #d8f3dc;
  font-size: 0.8rem;
  margin: 10px 0;
  .content {
    font-size: 1.3rem;
    padding: 6px 10px;
    border-radius: 10px;
    background: $primary;
    font-weight: bold;
  }
}

.delete-filter-icon {
  cursor: pointer;
}

.filter-input {
  font-size: 0.7rem;
  border-color: transparent;
  background-color: $light;
  box-shadow: 0 1px 0 darken($light, 5);

  &:focus {
    box-shadow: none;
  }
}

.filter-label {
  font-size: 0.9rem;
}

.videos-wrap {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-card {
  background-color: $white;
  border: 1px solid $gray-200;
  width: 255px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 15px 0 #b8bdd2 !important;
  }

  &__img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 200px;
    max-width: 100%;
  }

  &__body {
    padding: 0 15px;
    padding-top: 20px;
    padding-bottom: 5px;
    border-top: 1px solid $gray-200;
  }

  &__title {
    font-size: 1rem;
    color: $gray-700;
    font-weight: bold;
  }

  &__summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  &__footer {
    padding: 7px 0;
    border-top: 1px dashed $gray-200;
  }
}
.view-video-modal {
  .video-embed-wrap {
    height: 400px;
    background-color: $primary;

    iframe {
      width: 100%;
      min-height: 400px;
    }
  }

  .modal-dialog {
    max-width: 700px;
  }

  .modal-video-stats {
    width: 40%;
  }
}
</style>
