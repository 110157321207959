<template>
  <transition name="fade" mode="out-in"> <router-view /> </transition>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '~@/scss/variables';

.find-page-wrap {
  padding-top: $topbar-height;
}
</style>
