<template>
  <b-row no-gutters>
    <MemesSidebar />

    <b-col md="10">
      <section class="mx-4 my-5">
        <div class="w-50 mx-auto mb-3 discover__menu">
          <h4 class="mb-3 mt-0 text-center discover__menu-title">Find Memes by Keyword</h4>
          <div class="discover__menu-search">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="search-addon1" @click="refetchContents">
                  <img src="@/assets/icons/new/search.svg" alt="search icon" />
                </span>
              </div>
              <input
                type="text"
                class="form-control h-100"
                placeholder="Type a video keyword and hit enter"
                aria-label="search"
                aria-describedby="search-addon1"
                v-model="searchInput"
                @keyup.enter="refetchContents"
              />
            </div>
            <div>
              <div class="d-flex justify-content-between">
                <b-button
                  variant="clear"
                  class="discover__menu-advanced-toggle youtube"
                  v-b-tooltip.hover
                  title="Giphy"
                  :class="{ active: platform === 'giphy' }"
                  @click="setActivePlatform('giphy')"
                >
                  <img class="ml-2" src="@/assets/icons/new/giphy.svg" alt="search icon" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  title="Imgur"
                  variant="clear"
                  class="discover__menu-advanced-toggle"
                  :class="{ active: platform === 'imgur' }"
                  @click="setActivePlatform('imgur')"
                >
                  <img src="@/assets/icons/new/imgur.svg" alt="search icon" />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <b-row>
          <b-col md="12">
            <template v-if="platform === 'giphy'">
              <div class="article-results-label">
                <span v-if="!queryLoading && hasGifs && $route.query.q" class="content">
                  {{ gifSearch.gifs.length }} Results for "{{ $route.query.q }}"
                </span>
                <span v-else-if="queryLoading">Hold on...</span>
                <span v-else-if="!queryLoading && hasGifs && !$route.query.q" class="content">
                  Trending
                </span>
              </div>

              <div class="find-gifs-wrap">
                <skeleton-screens
                  v-if="queryLoading"
                  class="d-flex justify-content-between flex-wrap"
                  itemClass="find-gif-item"
                  width="250px"
                  height="250px"
                  times="12"
                />
                <template v-if="!queryLoading && hasGifs">
                  <div class="masonry">
                    <div v-for="content in gifSearch.gifs" :key="content.id">
                      <div class="find-gif-item">
                        <lazy-image :src="content.imageUrl" />

                        <div class="meme-footer">
                          <div class="meme-caption">{{ content.title }}</div>

                          <div class="meme-btns">
                            <b-button
                              v-b-tooltip.hover
                              title="Share on Blog"
                              variant="clear"
                              size="sm"
                              class="px-4 mr-2"
                              @click="handleShareClick(content, 'blog')"
                            >
                              <svg-icon name="wordpress"></svg-icon>
                            </b-button>
                            <b-button
                              v-b-tooltip.hover
                              title="Share on Social Media"
                              variant="clear"
                              size="sm"
                              class="px-4"
                              @click="handleShareClick(content, 'social')"
                            >
                              <svg-icon name="social"></svg-icon>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="!hasGifs && !queryLoading">
                  <div class="bg-white text-center rounded-sm shadow-sm p-5">
                    <div class="m-5 text-muted">
                      <LostImage class="w-25 py-3" />
                      <p class="mb-0">
                        No results for <strong>"{{ $route.query.q }}"</strong>. Please try a different keyword.
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else-if="platform === 'imgur'">
              <div class="article-results-label">
                <span v-if="!queryLoading && hasMemes && $route.query.q" class="content">
                  {{ memeSearch.memes.length }} Results for "{{ $route.query.q }}"
                </span>
                <span v-else-if="queryLoading">Hold on...</span>
                <span v-else-if="!queryLoading && hasMemes && !$route.query.q" class="content">
                  Trending
                </span>
              </div>

              <div class="find-memes-wrap">
                <skeleton-screens
                  v-if="queryLoading"
                  class="d-flex justify-content-between flex-wrap"
                  itemClass="find-meme-item"
                  width="250px"
                  height="250px"
                  times="12"
                />
                <template v-if="!queryLoading && hasMemes">
                  <div class="masonry">
                    <div v-for="meme in memeSearch.memes" :key="meme.id">
                      <div class="find-meme-item">
                        <b-badge
                          v-if="meme.isAlbum && meme.imagesCount > 1"
                          variant="primary"
                          class="image-count shadow-sm"
                        >
                          {{ meme.imagesCount }}
                        </b-badge>
                        <div
                          v-if="meme.type === 'image' || meme.type === 'gif'"
                          title="Click to enlarge"
                          :style="{ backgroundImage: `url(${meme.url})` }"
                          class="meme-img"
                          @click="handleViewMemeClick(meme)"
                        ></div>
                        <video
                          v-else-if="meme.type === 'video'"
                          loop
                          title="Click to enlarge"
                          class="meme-video"
                          muted
                          @mouseover="videoHoverHandler"
                          @mouseleave="videoUnHoverHandler"
                          @click="handleViewMemeClick(meme)"
                        >
                          <source type="video/mp4" :src="meme.url" />
                        </video>
                        <b-badge v-if="meme.type === 'video'" variant="danger" class="meme-type">
                          <svg-icon name="film" class="mt-0" />
                        </b-badge>

                        <div class="meme-footer">
                          <div class="meme-caption" :title="meme.title">{{ meme.title }}</div>

                          <div class="meme-stats">
                            <div v-b-tooltip title="Views" class="meme-stats-item">
                              <div class="meme-stats-value">
                                <svg-icon name="view" class="mt-0" />
                                {{ numFormat(meme.views) }}
                              </div>
                            </div>
                            <div v-b-tooltip title="Upvotes" class="meme-stats-item">
                              <div class="meme-stats-value">
                                <svg-icon name="up" />
                                {{ numFormat(meme.ups) }}
                              </div>
                            </div>
                            <div v-b-tooltip title="Downvotes" class="meme-stats-item">
                              <div class="meme-stats-value">
                                <svg-icon name="down" class="mt-0" />
                                {{ numFormat(meme.downs) }}
                              </div>
                            </div>
                          </div>

                          <div class="meme-btns">
                            <b-button
                              :id="`blog-meme-btn-${meme.id}`"
                              variant="clear"
                              size="sm"
                              class="px-4 mr-2"
                              @click="handleShareMeme(meme, 'blog')"
                            >
                              <svg-icon name="wordpress"></svg-icon>
                            </b-button>
                            <b-tooltip
                              :target="`blog-meme-btn-${meme.id}`"
                              :variant="meme.type === 'video' ? 'danger' : null"
                              triggers="hover"
                            >
                              {{ meme.type === 'video' ? 'Video not yet supported in blog editor' : 'Share on Blog' }}
                            </b-tooltip>
                            <b-button
                              v-b-tooltip.hover
                              title="Share on Social Media"
                              variant="clear"
                              size="sm"
                              class="px-4"
                              @click="handleShareMeme(meme, 'social')"
                            >
                              <svg-icon name="social"></svg-icon>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="!hasMemes && !queryLoading">
                  <div class="bg-white text-center rounded-sm shadow-sm p-5">
                    <div class="m-5 text-muted">
                      <LostImage class="w-25 py-3" />
                      <p class="mb-0">
                        No results for <strong>"{{ $route.query.q }}"</strong>. Please try a different keyword.
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </b-col>
        </b-row>
      </section>
    </b-col>

    <b-modal
      id="FinderViewMemeModal"
      size="lg"
      body-class="p-0"
      modal-class="finder-view-meme-modal"
      content-class="border-0 overflow-hidden rounded"
      centered
      hide-header
      hide-footer
    >
      <template v-slot="{ hide }">
        <div class="modal-meme-details border-bottom py-2 px-3">
          <div class="d-flex justify-content-between">
            <h5 class="font-weight-bold" :title="viewMeme.title">{{ viewMeme.title }}</h5>

            <b-button variant="link" class="text-muted" @click="hide"> <svg-icon name="close"></svg-icon> </b-button>
          </div>
          <div class="modal-meme-stats d-flex">
            <div v-b-tooltip title="Views" class="mr-3">
              <svg-icon name="view" class="mt-0"></svg-icon>
              {{ numFormat(viewMeme.views) }}
            </div>
            <div v-b-tooltip title="Upvotes" class="mr-3">
              <svg-icon name="up"></svg-icon>
              {{ numFormat(viewMeme.ups) }}
            </div>
            <div v-b-tooltip title="Downvotes" class="mr-3">
              <svg-icon name="down"></svg-icon>
              {{ numFormat(viewMeme.downs) }}
            </div>
          </div>
        </div>
        <template v-if="viewMeme.isAlbum">
          <div
            v-for="(image, i) in viewMeme.images"
            :key="image.id"
            class="img-wrap shadow-sm"
            :class="{ 'mb-3': i !== viewMeme.images.length - 1 }"
          >
            <div
              v-if="viewMeme.type === 'image' || viewMeme.type === 'gif'"
              class="modal-img"
              :style="{ backgroundImage: `url(${image.url})` }"
            ></div>
            <VideoPlayer
              v-if="viewMeme.type === 'video'"
              :width="700"
              :height="500"
              :src="viewMeme.url"
              :autoplay="i === 0 ? 'any' : 'false'"
            />
          </div>
        </template>
        <template v-else>
          <div class="img-wrap">
            <div
              v-if="viewMeme.type === 'image' || viewMeme.type === 'gif'"
              class="modal-img"
              :style="{ backgroundImage: `url(${viewMeme.url})` }"
            ></div>
            <VideoPlayer
              v-if="viewMeme.type === 'video'"
              :width="700"
              :height="500"
              :src="viewMeme.url"
              autoplay="true"
            />
          </div>
        </template>
        <div class="border-top">
          <div class="text-right mt-3 mb-2 mr-2">
            <b-button @click="hide" size="sm" variant="link" class="text-muted px-4"> Close </b-button>
            <b-button
              :id="`blog-viewmeme-btn-${viewMeme.id}`"
              variant="outline-secondary"
              size="sm"
              class="px-4 mr-2"
              @click="handleShareMeme(viewMeme, 'blog')"
            >
              <svg-icon name="wordpress" />
              Share on Blog
            </b-button>
            <b-tooltip
              v-if="viewMeme.type === 'video'"
              :target="`blog-viewmeme-btn-${viewMeme.id}`"
              :variant="viewMeme.type === 'video' ? 'danger' : null"
              triggers="hover"
            >
              Video not yet supported in blog editor
            </b-tooltip>
            <b-button variant="outline-secondary" size="sm" class="px-4" @click="handleShareMeme(viewMeme, 'social')">
              <svg-icon name="social" />
              Share on Social Media
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import { GIF_SEARCH_QUERY, MEME_SEARCH_QUERY, CURATED_TOPICS_QUERY } from '~/graphql/queries';

import app from '~/main';
import { numFormat } from '~/utils/helpers';

import LazyImage from '~/components/LazyImage';
import VideoPlayer from '~/components/VideoPlayer';
import MemesSidebar from '~/components/Memes/MemesSidebar';

import LostImage from '~/assets/images/lost.svg?inline';

export default {
  components: {
    LazyImage,
    VideoPlayer,
    MemesSidebar,
    LostImage,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      await app.$apollo
        .query({
          query: CURATED_TOPICS_QUERY,
        })
        .then(({ data }) => {
          app.$store.dispatch('topics/updateCurated', data.curatedTopics);
        });

      next();
    } catch (e) {
      next();
    }
  },

  data() {
    return {
      offset: 0,
      gifSearch: {},
      memeSearch: {},
      searchInput: this.$route.query.q,
      queryLoading: false,
      viewMeme: null,
    };
  },

  computed: {
    hasMemes() {
      return this.memeSearch.memes && this.memeSearch.memes.length > 0;
    },

    hasGifs() {
      return this.gifSearch.gifs && this.gifSearch.gifs.length > 0;
    },

    platform() {
      return this.$route.query.platform || 'giphy';
    },
  },

  watch: {
    $route(to) {
      if (this.platform === 'giphy') {
        if (to.query.q) {
          this.searchInput = to.query.q;
          this.findGifs(to.query.q);
        } else {
          this.searchInput = '';
          this.findGifs('');
        }
      } else {
        if (to.query.q) {
          this.searchInput = to.query.q;
          this.findMemes(to.query.q);
        } else {
          this.searchInput = '';
          this.findMemes('');
        }
      }
    },
  },

  created() {
    if (this.platform === 'giphy') {
      this.findGifs(this.$route.query.q || '');
    } else {
      this.findMemes(this.$route.query.q || '');
    }
  },

  methods: {
    numFormat,

    handleViewMemeClick(meme) {
      this.viewMeme = meme;

      this.$bvModal.show('FinderViewMemeModal');
    },

    videoHoverHandler(e) {
      let playPromise;
      const video = e.target;

      playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started!
          })
          .catch(() => {
            // Play was prevented
          });
      }
    },

    videoUnHoverHandler(e) {
      const video = e.target;

      video.pause();
    },

    shareActiveMeme() {
      this.$emit('insert', this.viewMeme);

      this.$bvModal.hide('FinderViewMemeModal');
    },

    setActivePlatform(platform) {
      this.$router.push({ query: { q: this.$route.query.q, platform } }, () => {});
    },

    findGifs(keywords) {
      this.queryLoading = true;

      this.$apollo
        .query({
          query: GIF_SEARCH_QUERY,
          variables: {
            keywords,
            offset: 0,
            limit: 50,
          },
        })
        .then(({ data }) => {
          this.queryLoading = false;

          this.gifSearch = data.gifSearch;
        })
        .catch(({ graphQLErrors: errors }) => {
          errors.forEach(() => {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          });

          this.queryLoading = false;
        });
    },

    findMemes(keywords) {
      this.queryLoading = true;

      this.$apollo
        .query({
          query: MEME_SEARCH_QUERY,
          variables: {
            query: keywords,
            offset: 0,
            limit: 50,
          },
        })
        .then(({ data }) => {
          this.queryLoading = false;

          this.memeSearch = data.memeSearch;
        })
        .catch(({ graphQLErrors: errors }) => {
          errors.forEach(() => {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          });

          this.queryLoading = false;
        });
    },

    refetchContents() {
      this.loadMoreEnabled = true;

      this.$router.push({ query: { q: this.searchInput, platform: this.platform } }, () => {});
    },

    handleShareClick(meme, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: meme.title,
        });

        this.$store.dispatch('createPost/updateSelectedImages', [
          {
            url: meme.imageUrl,
            thumb: meme.thumb,
            type: 'gif',
            platform: 'giphy',
          },
        ]);
      } else if (type === 'blog') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: `<div>
            <figure class="text-center">
              <img class="mw-100" src="${meme.imageUrl}" alt="" />
              <figcaption>${meme.title}</figcaption>
            </figure>
            <p><br /></p>
          </div>`,
        });
      }

      this.$router.push({ name: 'compose', params: { type, id: 0 } });
    },

    handleShareMeme(meme, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          body: meme.title,
        });

        const attachments = meme.isAlbum
          ? meme.images.map(img => ({
              url: img.url,
              thumb: img.type === 'video' ? null : img.url,
              type: img.type,
              platform: 'imgur',
            }))
          : {
              url: meme.url,
              thumb: meme.type === 'video' ? null : meme.url,
              type: meme.type,
              platform: 'imgur',
            };

        this.$store.dispatch('createPost/updateSelectedImages', [...attachments]);
      } else if (type === 'blog') {
        if (meme.type === 'video') return;

        this.$store.dispatch('richEditor/invokeMeme', meme);
      }

      this.$router.push({ name: 'compose', params: { type, id: 0 } });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.discover__menu {
  &-title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;

    .input-group {
      color: $primary !important;
      border-radius: 0.3rem !important;
      background-color: #ffffff !important;
      input {
        font-size: 0.9rem;
        height: 40px;
        &::placeholder {
          color: $gray-500;
        }
      }
    }
    input,
    .input-group-prepend,
    .input-group-text {
      // width: 1.8rem;
      background-color: transparent;
      border: none;
      outline: none;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
  &-advanced-toggle {
    background-color: #d8f3dc;
    border-radius: 0.3rem;
    border: 1px solid #52b788;
    height: 40px;
    width: 64px;
    margin-top: -18px;
    margin-left: 10px;
    text-align: center;

    &:focus {
      box-shadow: none;
    }

    .icon {
      width: 0.8rem;
      height: 0.9rem;
    }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

.find-gifs-wrap {
  margin-top: 1.5rem;
  width: 100%;

  .masonry {
    column-count: 4;
    column-gap: 1em;
  }
}
.find-gif-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 1em;
  background-color: $white;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  position: relative;

  img {
    display: inline-block;
    width: 97%;
    min-height: 100px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
    border-radius: 4px;
    box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);
    @include skeleton-animation(lightgray);
  }

  .meme-video {
    width: 100%;
    min-height: 250px;
    max-height: 250px;
  }

  .meme-type {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .meme-footer {
    padding: 10px;

    .meme-caption {
      font-size: 0.9rem;
      padding-bottom: 10px;
    }

    .meme-btns {
      border-top: 1px solid $gray-200;
      text-align: center;
      padding-top: 10px;

      .btn {
        color: #989eb5;
        background-color: #f2f7fa;
        box-shadow: 0 1px 0 0 rgba(184, 189, 209, 0.3);

        &:hover {
          background-color: #e6f2fa;
        }
      }
    }
  }
}

.find-memes-wrap {
  margin: 1.5rem;
  width: 100%;

  .masonry {
    column-count: 4;
    column-gap: 1em;
  }
}

.find-meme-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 1em;
  background-color: $white;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  position: relative;

  .image-count {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .meme-img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: 96%;
    height: 250px;
    background-color: $primary;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .meme-video {
    width: 96%;
    min-height: 250px;
    max-height: 250px;
    background-color: $primary;
  }

  .meme-img,
  .meme-video {
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    transition: margin 0.3s cubic-bezier(0.52, 0.28, 0.47, 1.15);
    box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);
  }

  .meme-type {
    position: absolute;
    top: 225px;
    right: 20px;
    transition: top 0.3s cubic-bezier(0.52, 0.28, 0.47, 1.15);
  }

  .meme-footer {
    padding: 10px;

    .meme-caption {
      font-size: 0.9rem;
      padding-bottom: 10px;

      height: 45px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-word;
    }

    .meme-stats {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $gray-200;
      padding-top: 10px;
      margin-bottom: 10px;

      .meme-stats-item {
        text-align: center;
        border-right: 1px solid $gray-200;
        width: 100%;

        &:last-of-type {
          border-right: 0;
        }

        .meme-stats-name {
          font-size: 0.7rem;
          margin-bottom: 2px;
        }
        .meme-stats-value {
          font-size: 0.8rem;
        }
      }
    }

    .meme-btns {
      border-top: 1px solid $gray-200;
      text-align: center;
      padding-top: 10px;

      .btn {
        color: #989eb5;
        background-color: #f2f7fa;
        box-shadow: 0 1px 0 0 rgba(184, 189, 209, 0.3);

        &:hover {
          background-color: #e6f2fa;
        }
      }
    }
  }

  &:hover {
    .meme-img,
    .meme-video {
      margin-top: -5px;
      margin-bottom: 20px;
    }

    .meme-type {
      top: 215px;
    }
  }
}
.article-results-label {
  font-size: 0.8rem;
  max-width: 200px;
  margin: 10px 0;
  .content {
    color: #d8f3dc;
    font-size: 1.3rem;
    padding: 6px 10px;
    border-radius: 10px;
    background: $primary;
    font-weight: bold;
  }
}

.finder-view-meme-modal {
  .modal-dialog {
    max-width: 900px;
  }

  .img-wrap {
    max-height: 90vh;
    overflow: hidden;
    background-color: $primary;

    .modal-img {
      height: 90vh;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .view-meme-close-btn {
    position: absolute;
    right: -50px;

    .icon {
      fill: $white;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
</style>
