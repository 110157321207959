<template>
  <b-row no-gutters>
    <QuotesSidebar :tags="tags" />

    <b-col md="10">
      <section class="my-4 mx-4">
        <div class="w-50 mx-auto mb-3 discover__menu">
          <h4 class="mb-3 mt-0 text-center discover__menu-title">Search Quotes by Keyword</h4>
          <div class="discover__menu-search">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="search-addon1" @click="refetchContents">
                  <img src="@/assets/icons/new/search.svg" alt="search icon" />
                </span>
              </div>
              <input
                type="text"
                class="form-control h-100"
                placeholder="Enter keyword..."
                aria-label="search"
                aria-describedby="search-addon1"
                v-model="searchInput"
                @keyup.enter="refetchContents"
              />
            </div>
          </div>
        </div>

        <b-row>
          <b-col md="12">
            <b-row cols="3" class="quotes-wrap" v-if="!loadingMore && queryLoading">
              <b-col v-for="i in 9" :key="i">
                <quote-skeleton itemClass="find-quote-item shadow-sm" />
              </b-col>
            </b-row>
            <b-row cols="3" v-if="(hasQuotes && !queryLoading) || (hasQuotes && loadingMore)" class="quotes-wrap">
              <b-col v-for="quote in quoteSearch.quotes" :key="quote.id" class="">
                <div class="find-quote-item">
                  <div :title="quote.body" class="quote-card" :style="getRandomColor(quote)">
                    <div class="quote-body">
                      {{ quote.body | truncate(100, '...') }}
                    </div>
                    <div class="quote-author">
                      <em>{{ quote.author }}</em>
                    </div>
                  </div>

                  <div class="quote-btns mt-2 text-center">
                    <b-button
                      v-b-tooltip.hover
                      title="Design Image Quote"
                      variant="clear"
                      size="md"
                      class="px-4 mr-2"
                      @click="designQuote(quote)"
                    >
                      <svg-icon name="art" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      title="Share on Social Media"
                      variant="clear"
                      size="md"
                      class="px-4 mr-2"
                      @click="handleShareClick(quote, 'social')"
                    >
                      <svg-icon name="social" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      title="Insert into Blog composer"
                      variant="clear"
                      size="md"
                      class="px-4"
                      @click="handleShareClick(quote, 'blog')"
                    >
                      <svg-icon name="wordpress" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div v-if="loadingMore" class="text-center mt-4"><spinner size="2" /></div>
            <div v-if="!hasQuotes && !queryLoading" class="text-center white p-5">
              <h3>Sorry, no results found for "{{ searchInput }}"</h3>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';

import app from '~/main';

import QuoteSkeleton from '~/components/QuoteSkeleton';
import QuotesSidebar from '~/components/Quotes/QuotesSidebar';

import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

import { QUOTE_SEARCH_QUERY, QUOTE_TYPE_AHEAD_QUERY } from '~/graphql/queries';

export default {
  components: {
    QuotesSidebar,
    QuoteSkeleton,
  },

  mixins: [InfiniteScrollMixin],

  async beforeRouteEnter(to, from, next) {
    await app.$apollo
      .query({
        query: QUOTE_TYPE_AHEAD_QUERY,
      })
      .then(({ data }) => {
        next(vm => {
          vm.tags = data.quoteTypeAhead.tags;
          vm.authors = data.quoteTypeAhead.authors;
        });
      });

    next();
  },

  data() {
    return {
      tags: [],
      authors: [],
      quoteSearch: {},
      searchInput: this.$route.query.q,
      currentPage: 1,
      loadingMore: false,
      colorCache: [],
    };
  },

  apollo: {
    quoteSearch: {
      query: QUOTE_SEARCH_QUERY,
      variables() {
        return {
          keywords: this.$route.query.q,
          page: 1,
        };
      },
      result({ data }) {
        this.loadMoreEnabled = data.quoteSearch.hasMore;
      },
      error() {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'An error occurred while processing your request.',
        });
      },
    },
  },

  watch: {
    '$route.query.q'() {
      this.currentPage = 1;
    },

    isRockBottom(isRockBottom) {
      if (!this.loadingMore && isRockBottom && this.hasQuotes && this.loadMoreEnabled) {
        this.loadMore();
      }
    },
  },

  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  computed: {
    ...mapState('createPost', {
      postSaving: state => state.postSaving,
      selectedContent: state => state.selectedContent,
    }),

    queryLoading() {
      return this.$apollo.queries.quoteSearch && this.$apollo.queries.quoteSearch.loading;
    },

    hasQuotes() {
      return this.quoteSearch.quotes && this.quoteSearch.quotes.length > 0;
    },
  },

  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  methods: {
    scrollHandler() {
      this.isRockBottom = this.rockBottom();
    },

    getRandomColor(quote) {
      const colors = ['#E1EAFF', '#D8F3DC', '#FFEAC6'];
      const activeColor = colors[Math.floor(Math.random() * colors.length)];

      return this.colorCache[quote] || (this.colorCache[quote] = activeColor);
    },

    refetchContents() {
      this.$router.push(
        {
          query: { q: this.searchInput },
        },
        () => {},
      );
    },

    designQuote(quote) {
      this.$store.dispatch('design/updateQuote', quote);

      this.$router.push({ name: 'designer.edit', params: { id: 0 } });
    },

    async handleShareClick(quote, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          ...quote,
          body: `${quote.body} \n\n ${quote.author}`,
        });
      } else if (type === 'blog') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          ...quote,
          body: `<blockquote>
            ${quote.body} <br /> <strong><em>${quote.author}</em></strong>
          </blockquote>`,
        });
      }

      this.$router.push({ name: 'compose', params: { type, id: 0 } });
    },

    async loadMore() {
      this.loadingMore = true;

      try {
        let variables = {
          keywords: this.$route.query.q,
          page: ++this.currentPage,
        };

        // Fetch more data and transform the original result
        await this.$apollo.queries.quoteSearch.fetchMore({
          // New variables
          variables,
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newQuotes = fetchMoreResult.quoteSearch.quotes;
            const hasMore = fetchMoreResult.quoteSearch.hasMore;

            this.loadMoreEnabled = hasMore;

            return {
              quoteSearch: {
                ...previousResult.quoteSearch,
                __typename: previousResult.quoteSearch.__typename,
                // Merging the tag list
                quotes: [...previousResult.quoteSearch.quotes, ...newQuotes],
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        console.log(e.message);
      }

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.discover__menu {
  &-title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;

    .input-group {
      color: $primary !important;
      border-radius: 0.3rem !important;
      background-color: #ffffff !important;
      input {
        font-size: 0.9rem;
        height: 40px;
        &::placeholder {
          color: $gray-500;
        }
      }
    }
    input,
    .input-group-prepend,
    .input-group-text {
      // width: 1.8rem;
      background-color: transparent;
      border: none;
      outline: none;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
  &-advanced-toggle {
    background-color: #d8f3dc;
    border-radius: 0.3rem;
    border: 1px solid #52b788;
    height: 40px;
    width: 56px;
    margin-top: -18px;
    margin-left: 10px;
    text-align: center;

    &:focus {
      box-shadow: none;
    }

    .icon {
      width: 0.8rem;
      height: 0.9rem;
    }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

.quotes-wrap {
  margin: 1.5rem;
}

$black: #222222;
$blue: #303f9f;
$blueTextColor: $white;
$blueSecondaryTextColor: #3f51b5;

.find-quote-item {
  margin-bottom: 30px;
  background-color: #ffeac8;
  border-radius: 7px;

  .quote-card {
    padding: 50px 30px 10px 30px;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    background: #ffeac6;
    color: $primary;
    transition: margin 0.3s cubic-bezier(0.52, 0.28, 0.47, 1.15);
    // box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);

    &:before {
      font-family: Georgia, serif;
      content: '“';
      position: absolute;
      top: -40px;
      left: 5px;
      transform: scale(1.2);
      font-size: 8em;
      color: #f8b95b;
      font-weight: normal;
    }
  }

  &:hover {
    .quote-card {
      margin-top: -20px;
      margin-bottom: 20px;
    }
  }

  .quote-body {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    margin-bottom: 20px;
    min-height: 170px;
    font-weight: bold;
    font-size: 1rem;
  }

  .quote-author {
    position: relative;
    z-index: 2;
    text-align: left;

    em {
      opacity: 0.8;
    }
  }

  .quote-btns {
    .btn {
      color: #f8b95b;
      background-color: #ffeac6;
      box-shadow: 0 1px 0 0 rgba(184, 189, 209, 0.3);

      .icon {
        margin-top: 0;
      }

      &:hover {
        background-color: #f8b95b;
        color: #fff;
      }
    }
  }
}
</style>
