<template>
  <b-row no-gutters>
    <ArticlesSidebar />

    <b-col cols="10">
      <div class="article-find-page-wrap py-4 mx-4">
        <div class="w-50 mx-auto mb-3 discover__menu">
          <h4 class="mb-3 mt-0 text-center discover__menu-title">Discover Articles</h4>

          <div class="discover__menu-search">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="search-addon1" @click="refetchContents">
                  <img src="@/assets/icons/new/search.svg" alt="search icon" />
                </span>
              </div>
              <input
                type="text"
                class="form-control h-100"
                placeholder="Search for multiple topics, e.g 'marketing, technology'"
                aria-label="search"
                aria-describedby="search-addon1"
                v-model="searchInput"
                @keyup.enter="refetchContents"
              />
            </div>
            <div>
              <b-button
                @click="toggleShowFilters"
                variant="clear"
                class="discover__menu-advanced-toggle"
                :class="{ active: showFilters }"
                v-b-tooltip.hover
                title="Toggle Advanced filters"
              >
                <img src="@/assets/icons/new/down-toggle.svg" alt="search icon" />
              </b-button>
            </div>
          </div>

          <div v-show="showFilters" class="article-filters-wrap shadow-sm py-4 px-3 mt-3">
            <div class="filters-header">Refine your search</div>

            <div class="refine-query-body">
              <b-form-group label-for="exclude-keywords">
                <div class="filter-label">Exclude irrelevant keywords</div>
                <vue-tags-input
                  id="exclude-keywords"
                  v-model="excludeKeywordInput"
                  :tags="excludeKeywords"
                  :add-on-key="[13, ',', ';']"
                  :allow-edit-tags="true"
                  :placeholder="
                    excludeKeywords.length
                      ? 'Add keywords'
                      : 'Enter irrelevant keywords here to remove irrelevant results'
                  "
                  @tags-changed="handleExcludeKeywordsChange"
                />
              </b-form-group>
            </div>
            <b-row class="article-filter-row pt-2" no-gutters>
              <b-col md="3" class="mr-2">
                <div class="filter-label">Date</div>
                <b-form-select v-model="postAge" class="filter-input">
                  <option value="1">Last 24 Hours</option>
                  <option value="7">Last 7 Days</option>
                  <option value="30">Last 30 Days</option>
                  <option value="365">1 Year Ago</option>
                </b-form-select>
              </b-col>
              <b-col class="mr-2">
                <div class="filter-label">Language</div>
                <b-form-select v-model="language" class="filter-input">
                  <option v-for="lang in langs" :key="lang.code" :value="lang.code">{{ lang.name }}</option>
                </b-form-select>
              </b-col>
              <b-col class="mr-2">
                <div class="filter-label">Sort By</div>
                <b-form-select v-model="sort" class="filter-input">
                  <option value="total_share_count">Most Shared</option>
                  <option value="facebook_share_count">Facebook Shares</option>
                  <option value="pinterest_share_count">Pinterest Shares</option>
                  <option value="twitter_share_count">Twitter Shares</option>
                </b-form-select>
              </b-col>
            </b-row>

            <div class="px-2 text-right">
              <b-button @click="toggleShowFilters" variant="outline-primary" class="px-4 mr-2">Close</b-button>
              <b-button @click="refetchContents" variant="primary" class="px-4" :disabled="!canSubmit">
                Apply
                <b-spinner v-if="queryLoading" class="ml-1" small />
              </b-button>
            </div>
          </div>
        </div>

        <b-row>
          <b-col md="12">
            <div class="article-results-label">
              <span v-if="$route.query.topic" class="content">{{ $route.query.topic }}</span>
              <span v-else-if="!searchInput" class="content">Articles</span>
              <span v-else-if="queryLoading">Hold on...</span>
              <span v-else class="content">{{ articles.length }} Results</span>
            </div>
            <article-skeleton v-if="!loadingMore && queryLoading && !articles.length" class="article-contents-wrap" />
            <b-row cols="4" v-if="articles.length" class="">
              <b-col class="m-0 p-0 w-100" v-for="article in articles" :key="article.id">
                <b-card
                  :img-src="article.image"
                  :img-alt="article.title"
                  img-top
                  tag="article"
                  style="max-width: 20rem;"
                  class="m-2 shadow-sm article-card"
                >
                  <b-card-text>
                    <div v-if="descape(article.title)" class="article-content-wrap">
                      <a
                        href="#"
                        class="article-title"
                        v-html="descape(article.title)"
                        @click.prevent="readArticle(article)"
                      ></a>
                      <p class="article-desc">{{ article.description }}</p>
                      <div class="discover-article-footer">
                        <div class="article-stats">
                          <div :id="`article-${article.id}`" class="article-stats-item">
                            <div class="article-stats-name">
                              <span class="article-icon red-bg">
                                <img src="@/assets/icons/fire.svg" alt="search icon" />
                              </span>
                              Engagement
                            </div>
                            <div class="article-stats-value">{{ numFormat(article.engagement) }}</div>
                          </div>
                          <SharesPopover :target="`article-${article.id}`" :article="article" />
                          <div class="article-stats-item">
                            <div class="article-stats-name">
                              <span class="article-icon green-bg">
                                <img src="@/assets/icons/leaf.svg" alt="search icon" />
                              </span>
                              Trend score
                            </div>
                            <div class="article-stats-value">{{ numFormat(article.score) }}</div>
                          </div>
                          <div v-b-tooltip :title="capitalize(article.sentiment)" class="article-stats-item">
                            <div class="article-stats-name">
                              <span class="article-icon yellow-bg">
                                <img src="@/assets/icons/wheat.svg" alt="search icon" />
                              </span>
                              Sentiment
                            </div>
                            <SentimentIcon :sentiment="article.sentiment" />
                          </div>
                        </div>

                        <div class="article-btns text-center">
                          <b-button
                            v-b-tooltip.hover
                            title="Convert to Audio/Podcast"
                            variant="clear"
                            size="sm"
                            class="px-4 mr-2"
                            :disabled="!!getting"
                            @click="handleShareClick(article, 'voice')"
                          >
                            <span>
                              <img src="@/assets/icons/new/music.svg" alt="Audio/Podcast" />
                            </span>
                          </b-button>
                          <b-button
                            v-b-tooltip.hover
                            title="Share on Blog"
                            variant="clear"
                            size="sm"
                            class="px-4 mr-2"
                            :disabled="!!getting"
                            @click="handleShareClick(article, 'blog')"
                          >
                            <span>
                              <img src="@/assets/icons/new/share.svg" alt="Share on Blog" />
                            </span>
                          </b-button>
                          <b-button
                            v-b-tooltip.hover
                            title="Share on Social Media"
                            variant="clear"
                            size="sm"
                            class="px-4"
                            :disabled="!!getting"
                            @click="handleShareClick(article, 'social')"
                          >
                            <span>
                              <img src="@/assets/icons/new/globe.svg" alt="Share on Social Media" />
                            </span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <div v-if="loadingMore" class="text-center mt-4"><spinner size="2" /></div>
            <div v-if="articles.length < 1 && !queryLoading" class="bg-white text-center shadow-sm rounded-sm p-5">
              <img class="pt-3" width="300" src="@/assets/images/blank_canvas.svg" alt="" />
              <h3 class="m-5 text-muted">No results found.</h3>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { decode } from 'he';
import moment from 'moment';
import { mapState } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';

import app from '~/main';
import { capitalize } from 'lodash';
import { numFormat } from '~/utils/helpers';

import ArticleSkeleton from '~/components/ArticleSkeleton';
import SharesPopover from '~/components/Articles/SharesPopover';
import SentimentIcon from '~/components/Articles/SentimentIcon';
// import LazyBackgroundImage from '~/components/LazyBackgroundImage';
import ArticlesSidebar from '~/components/Articles/ArticlesSidebar';

import InfiniteScrollMixin from '~/mixins/InfiniteScrollMixin';

import {
  ARTICLE_SEARCH_QUERY,
  ARTICLE_SEARCH_BY_TOPIC_QUERY,
  GET_ARTICLE_QUERY,
  CURATED_TOPICS_QUERY,
} from '~/graphql/queries';

import { langSub } from '~/utils/languages';

export default {
  components: {
    ArticlesSidebar,
    VueTagsInput,
    SentimentIcon,
    SharesPopover,
    ArticleSkeleton,
    // LazyBackgroundImage,
  },

  mixins: [InfiniteScrollMixin],

  async beforeRouteEnter(to, from, next) {
    try {
      await app.$apollo
        .query({
          query: CURATED_TOPICS_QUERY,
        })
        .then(({ data }) => {
          app.$store.dispatch('topics/updateCurated', data.curatedTopics);
        });

      next();
    } catch (e) {
      next();
    }
  },

  data() {
    let excluded = [];

    const excludeKeywordquery = this.$route.query.excludeKeywords;
    if (excludeKeywordquery && typeof excludeKeywordquery === 'string') {
      excluded = [excludeKeywordquery];
    } else {
      excluded = excludeKeywordquery || [];
    }

    const excludeKeywords = excluded.map(w => ({ text: w }));

    return {
      getting: null,

      langs: langSub,
      keywords: [],
      excludeKeywords,

      excludeKeywordInput: '',
      searchInput: this.$route.query.q,
      showFilters: false,
      articleSearch: {},
      articleSearchByTopic: {},

      postAge: this.$route.query.postAge || '7',
      language: this.$route.query.language || 'en',
      sort: this.$route.query.sort || 'total_share_count',
      loadingMore: false,
      nextOffset: 0,
    };
  },

  computed: {
    ...mapState({
      campaignId: state => state.createPost.campaignId,
      curatedTopics: state => state.topics.curated,
    }),

    articles() {
      if (this.$route.query.topic) {
        return this.articleSearchByTopic.articles && this.articleSearchByTopic.articles.length
          ? this.articleSearchByTopic.articles
          : [];
      } else {
        return this.articleSearch.articles && this.articleSearch.articles.length ? this.articleSearch.articles : [];
      }
    },

    canSubmit() {
      return true;
    },

    queryLoading() {
      if (this.$route.query.topic) {
        return this.$apollo.queries.articleSearchByTopic && this.$apollo.queries.articleSearchByTopic.loading;
      } else {
        return this.$apollo.queries.articleSearch && this.$apollo.queries.articleSearch.loading;
      }
    },
  },

  filters: {
    timeAgo: function(date) {
      return moment(date).fromNow();
    },
  },

  watch: {
    // Had to handle each query param separately because
    // combining all ruins performance

    '$route.query.q'(value) {
      this.nextOffset = 0;
      this.fetchArticles(value || '');
    },
    '$route.query.topic'(value) {
      this.nextOffset = 0;
      if (value) {
        this.searchInput = '';
        this.fetchArticlesByTopic(value);
      } else {
        this.fetchArticles('');
      }
    },
    '$route.query.postAge'(value) {
      this.nextOffset = 0;
      if (value) {
        this.fetchArticles(this.$route.query.q);
      }
    },
    '$route.query.language'(value) {
      this.nextOffset = 0;
      if (value) {
        this.fetchArticles(this.$route.query.q);
      }
    },
    '$route.query.sort'(value) {
      this.nextOffset = 0;
      if (value) {
        this.fetchArticles(this.$route.query.q);
      }
    },

    '$route.query.excludeKeywords'(value) {
      this.nextOffset = 0;
      if (value) {
        this.fetchArticles(this.$route.query.q);
      }
    },

    isRockBottom(isRockBottom) {
      if (!this.loadingMore && isRockBottom && this.articles.length && this.loadMoreEnabled) {
        this.loadMore();
      }
    },
  },

  created() {
    if (this.$route.query.topic) {
      this.fetchArticlesByTopic(this.$route.query.topic);
    } else {
      const keyword = this.$route.query.q || '';
      this.fetchArticles(keyword);
    }

    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeDestroy() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  methods: {
    numFormat,
    capitalize,

    scrollHandler() {
      this.isRockBottom = this.rockBottom();
    },

    toggleShowFilters() {
      if (this.showFilters) {
        this.showFilters = false;
      } else {
        this.showFilters = true;
      }
    },

    handleExcludeKeywordsChange(excludeKeywords) {
      this.excludeKeywords = excludeKeywords;
    },

    readArticle(article) {
      this.$store.dispatch('article/invoke', article);

      this.$router.push({ query: { ...this.$route.query, read: article.id } });
    },

    fetchArticles(keyword) {
      const keywords = keyword || '';
      const variables = {
        keywords: keywords
          .split(',')
          .map(k => k.trim())
          .filter(k => k !== ''),
        excludeKeywords: this.excludeKeywords.map(t => t.text),
        language: this.language,
        postAge: this.postAge,
        nextOffset: 0,
        limit: 51,
        sort: this.sort,
      };

      if (!this.$apollo.queries.articleSearch) {
        this.$apollo.addSmartQuery('articleSearch', {
          query: ARTICLE_SEARCH_QUERY,
          variables,
          result({ data }) {
            // this.nextOffset = data.articleSearch.nextOffset;
            this.loadMoreEnabled = data.articleSearch.hasMore;
          },
          error() {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          },
        });
      } else {
        this.$apollo.queries.articleSearch.refetch(variables);
      }
    },

    fetchArticlesByTopic(topic) {
      const curatedTopic = this.curatedTopics.find(t => t.title == topic);

      const variables = {
        topic: {
          id: curatedTopic.id,
          name: curatedTopic.title,
          label: curatedTopic.label,
        },
        language: this.language,
        postAge: this.postAge,
        nextOffset: this.nextOffset,
        limit: 51,
        sort: this.sort,
      };

      if (!this.$apollo.queries.articleSearchByTopic) {
        this.$apollo.addSmartQuery('articleSearchByTopic', {
          query: ARTICLE_SEARCH_BY_TOPIC_QUERY,
          variables,
          result({ data }) {
            this.loadMoreEnabled = data.articleSearchByTopic.hasMore;
          },
          error() {
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'An error occurred while processing your request.',
            });
          },
        });
      } else {
        this.$apollo.queries.articleSearchByTopic.refetch(variables);
      }
    },

    refetchContents() {
      if (!this.canSubmit) {
        return;
      }

      const excludeKeywords = this.excludeKeywords.map(k => k.text);
      const query = {
        q: this.searchInput,
        postAge: this.postAge,
        language: this.language,
        sort: this.sort,
        excludeKeywords,
      };
      this.$router.push({ query }, () => {});
    },

    descape(text) {
      return decode(text);
    },

    handleShareClick(article, type) {
      this.$store.dispatch('createPost/updatePlatformType', type);
      if (type === 'social') {
        this.$store.dispatch('createPost/updateSelectedContent', {
          ...article,
          body: `${article.title} - <a href="${article.url}">${article.url}</a>`,
          postLink: article.url,
        });

        this.$store.dispatch('createPost/fetchLinkPreview', article.url);

        this.navigateToCompose(type);
      } else if (type === 'blog') {
        this.getting = article.id;

        this.$apollo
          .query({
            query: GET_ARTICLE_QUERY,
            variables: {
              id: article.id,
            },
          })
          .then(({ data }) => {
            this.getting = null;
            const articleText = data.getArticle;

            const credits = `<div>
              <br />
              <p><em>This Post was originally published on <a href="${article.url}">${article.domain}</a></em></p>
            </div>`;

            const postContent = `
              <img src="${article.image}" alt="" />
              <hr />
              ${articleText} ${credits}
            `;

            this.$store.dispatch('createPost/updateSelectedContent', {
              ...article,
              body: postContent,
              title: article.title,
              postLink: article.url,
            });

            this.navigateToCompose(type);
          })
          .catch(() => {
            this.getting = null;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });
      } else if (type === 'voice') {
        this.getting = article.id;

        this.$apollo
          .query({
            query: GET_ARTICLE_QUERY,
            variables: {
              id: article.id,
            },
          })
          .then(({ data }) => {
            this.getting = null;
            const articleText = data.getArticle;

            const postContent = `
              <p>${article.title}</p>
              ${articleText}
            `;

            this.$store.dispatch('voiceEditor/setPreContent', postContent);

            this.$router.push({ name: 'compose-audio', params: { type, id: 0 } });
          })
          .catch(() => {
            this.getting = null;

            this.$notify({
              group: 'main',
              type: 'native-error',
              text: 'An error occurred while processing your request.',
            });
          });
      }
    },

    navigateToCompose(type) {
      if (this.campaignId !== null) {
        this.$router.push({ name: 'campaign.posts', query: { compose: type, id: 0 } });

        return;
      }

      this.$router.push({ name: 'compose', params: { type, id: 0 } });
    },

    loadMore() {
      if (this.$route.query.topic) {
        this.loadMoreByTopic();
      } else {
        this.loadMoreByQuery();
      }
    },

    async loadMoreByQuery() {
      this.loadingMore = true;

      try {
        const nextOffset = this.nextOffset + 51;

        // Fetch more data and transform the original result
        await this.$apollo.queries.articleSearch.fetchMore({
          // New variables
          variables: { nextOffset },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newArticles = fetchMoreResult.articleSearch.articles;
            const hasMore = fetchMoreResult.articleSearch.hasMore;

            this.nextOffset = nextOffset;
            this.loadMoreEnabled = hasMore;

            return {
              articleSearch: {
                ...previousResult.articleSearch,
                __typename: previousResult.articleSearch.__typename,
                // Merging the tag list
                articles: [...previousResult.articleSearch.articles, ...newArticles],
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        // console.log(e.message);
      }

      this.loadingMore = false;
    },

    async loadMoreByTopic() {
      this.loadingMore = true;

      try {
        const nextOffset = this.nextOffset + 51;

        // Fetch more data and transform the original result
        await this.$apollo.queries.articleSearchByTopic.fetchMore({
          // New variables
          variables: { nextOffset },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newArticles = fetchMoreResult.articleSearchByTopic.articles;
            const hasMore = fetchMoreResult.articleSearchByTopic.hasMore;

            this.nextOffset = nextOffset;
            this.loadMoreEnabled = hasMore;

            return {
              articleSearchByTopic: {
                ...previousResult.articleSearchByTopic,
                __typename: previousResult.articleSearchByTopic.__typename,
                // Merging the tag list
                articles: [...previousResult.articleSearchByTopic.articles, ...newArticles],
                hasMore,
              },
            };
          },
        });
      } catch (e) {
        // console.log(e.message);
      }

      this.loadingMore = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.article-find-page-wrap {
  .card-item-skeleten {
    min-width: 278px;
  }
  .discover__menu {
    &-title {
      color: $primary;
      font-size: 1.5rem;
      font-weight: bold;
    }
    &-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px;

      .input-group {
        color: $primary !important;
        border-radius: 0.3rem !important;
        background-color: #ffffff !important;
        input {
          font-size: 0.9rem;
          height: 40px;
          &::placeholder {
            color: $gray-500;
          }
        }
      }
      input,
      .input-group-prepend,
      .input-group-text {
        // width: 1.8rem;
        background-color: transparent;
        border: none;
        outline: none;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
    &-advanced-toggle {
      background-color: #d8f3dc;
      border-radius: 0.3rem;
      border: 1px solid #52b788;
      height: 40px;
      margin-top: -18px;
      margin-left: 10px;

      &:focus {
        box-shadow: none;
      }

      .icon {
        width: 0.8rem;
        height: 0.9rem;
      }

      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .article-filters-wrap {
    background-color: #d8f3dc;
    border: 1px solid #52b788;
    position: relative;
    border-radius: 10px;

    &:before {
      content: '';
      background-color: transparent;
      z-index: 1;
      border-bottom: 12px solid #52b788;
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      border-top: 12px solid transparent;
      position: absolute;
      top: -25px;
      right: 20px;
    }
    &::after {
      content: '';
      background-color: transparent;
    }

    .filters-header {
      letter-spacing: 3px;
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
      font-weight: bold;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      color: #e6f2ea;
      background: #2d6a4f;
      border: 1px solid #52b788;
      // width: 60%;
    }

    .article-filter-row {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .vue-tags-input {
      max-width: 100% !important;
      background-color: lighten($light, 1);
      box-shadow: 0 1px 0 darken($light, 5);
      border-radius: 0.3rem;
      border: 1px solid $gray-100;

      .ti-input {
        border-color: transparent;
      }

      input {
        background-color: lighten($light, 1);
      }
    }
    .form-control {
      border: 0;
      font-size: 0.9rem;
      background-color: lighten($light, 1);
      box-shadow: 0 1px 0 darken($light, 5);

      &::placeholder {
        font-size: 0.7rem;
        color: $gray-600;
      }
    }
  }

  .delete-filter-icon {
    cursor: pointer;
  }

  .article-contents-wrap {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filter-input {
    font-size: 0.7rem;
    border-color: transparent;
    background-color: $light;
    box-shadow: 0 1px 0 darken($light, 5);

    &:focus {
      box-shadow: none;
    }
  }

  .filter-label {
    font-size: 0.95rem;
  }

  .article-results-label {
    color: #d8f3dc;
    font-size: 0.8rem;
    max-width: 200px;
    margin: 10px 0;
    .content {
      font-size: 1rem;
      padding: 6px 10px;
      border-radius: 10px;
      background: $primary;
      font-weight: bold;
      display: inline;
    }
  }

  .sidebar-header-text {
    text-transform: uppercase;
    color: $gray-500;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .input-icon {
    position: absolute;
    right: 14px;
    top: 8px;
    cursor: pointer;
  }
  .article-card {
    border-radius: 15px;
    .card-img-top {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 150px;
    }
    .card-body {
      padding: 0.8rem !important;
    }
  }

  .article-content-wrap {
    .article-title {
      color: $gray-600;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 1.2rem;
      font-weight: bold;
      word-break: break-word;
      margin-bottom: 4px;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
    .article-desc {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 0.9rem;
      word-break: break-word;
      margin-bottom: 4px;
    }
    .discover-article-footer {
      .article-icon {
        width: 50px;
        height: 50px;
        padding: 4px;
        line-height: 15px;
        vertical-align: middle;
        border-radius: 25px;
      }
      .yellow-bg {
        background: #ffc22b;
      }
      .red-bg {
        background: #f8593b;
      }
      .green-bg {
        background: #2ecc71;
      }
      padding: 10px 0;
      .article-stats {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $gray-200;
        border-bottom: 1px solid $gray-200;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 15px;

        .article-stats-item {
          text-align: center;
          width: 100%;

          .article-stats-name {
            font-size: 0.5rem;
            margin-bottom: 2px;
          }
          .article-stats-value {
            font-size: 0.8rem;
            font-weight: bold;
          }
        }
      }
      .article-btns {
        .btn {
          background: #f2f2f2;
          padding: 6px;
          border-radius: 0.2rem;
        }
      }
    }

    // &:hover {
    //   box-shadow: 0 2px 15px 0 #b8bdd2 !important;

    //   .article-img-top {
    //     margin-top: -15px;
    //   }
    // }
  }
}
</style>
