<template>
  <transition name="quotes-sidebar-slide">
    <b-col cols="2" class="quotes-sidebar-container">
      <div class="sidebar shadow-sm col-2">
        <div class="side-nav-wrap">
          <div class="text-center">
            <router-link :to="{ name: 'dashboard' }" class="back-btn">
              <svg-icon name="dashboard" size="sm" class="mx-1" />
              Back to Dashboard
            </router-link>
          </div>
          <!-- <div class="p-3">
            <WorkspaceMenu />
          </div> -->
          <ul class="side-nav my-4">
            <li>
              <router-link :to="{ name: 'find.quotes' }" active-class="active" exact>
                <svg-icon name="popular" />
                Random
              </router-link>
            </li>
          </ul>
          <div class="sidebar-subheader mt-2">
            Tags
          </div>
          <simplebar class="topics-scroll">
            <ul v-if="tags.length" class="side-nav">
              <li v-for="tag in tags" :key="tag.slug">
                <router-link
                  :to="{ query: { q: tag.slug } }"
                  :title="capitalize(tag.name)"
                  active-class="active"
                  class="text-truncate"
                  exact
                >
                  <span class="tag-icon" :style="getIconStyle()"></span>
                  {{ capitalize(tag.name) }}

                  <b-badge variant="info" class="d-inline-block ml-auto" pill>{{ tag.count }}</b-badge>
                </router-link>
              </li>
            </ul>
          </simplebar>
          <div class="sidebar-subheader mt-4">
            other content types
          </div>
          <ul class="side-nav">
            <li>
              <router-link :to="{ name: 'find.articles' }" active-class="active">
                <svg-icon name="globe" class="mx-2" />
                ArticlesV
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'find.memes' }" active-class="active">
                <svg-icon name="laugh" class="mx-2" />
                Memes
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'find.videos' }" active-class="active">
                <svg-icon name="video-player" class="mx-2" />
                Videos
              </router-link>
            </li>
          </ul>
        </div>

        <div class="trial-box">
          <a href="https://support.contentburger.io" target="_blank" class="px-3 py-1 trial-box__link">
            Help center
          </a>
        </div>
      </div>
    </b-col>
  </transition>
</template>

<script>
import { capitalize } from 'lodash';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
// import WorkspaceMenu from '../General/Menus/BrandMenu.vue';

export default {
  name: 'Sidebar',

  components: {
    simplebar,
    // WorkspaceMenu
  },

  props: {
    tags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    capitalize,

    getIconStyle() {
      let color;

      do {
        color = this.generateColor();
      } while (color === '#ffffff' || color.length < 7);

      return {
        backgroundColor: color,
      };
    },

    generateColor() {
      return '#' + ((Math.random() * 0xffffff) << 0).toString(16);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.quotes-sidebar-container {
  position: relative;
  .back-btn {
    font-weight: bold;
    text-align: center;
    align-items: center;
    width: 100%;
    display: block;
    // background: #40916c;
    background: #40916c;
    color: #e5fff1;
    padding: 10px;
    border-radius: 10px;
    &:hover {
      text-decoration: none;
    }
  }

  .sidebar {
    position: fixed;
    z-index: 100;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    color: $primary;
    overflow: visible;

    .side-nav-wrap {
      max-height: 100vh;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      height: 100%;
      overflow-y: auto;

      .simplebar-scrollbar {
        width: 10px;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        /* When hovered, remove all transitions from drag handle */
        opacity: 1;
        transition: opacity 0s linear;
      }
    }

    .side-nav {
      list-style: none;
      padding: 0;
      &__divider {
        // background: #52b788;
        padding: 0;
        margin: 5px 18px;
      }

      li {
        a {
          display: block;
          padding: 10px 15px;
          color: $primary;

          border-left: 2px solid transparent;

          &:hover {
            text-decoration: none;
            background-color: rgba($primary, 0.2);
          }
          &.active {
            border-left: 8px solid #42c589;
            box-shadow: 0 0 7px rgba($primary, 0.1);
          }
          &:hover,
          &.active {
            border-radius: 5px;
            font-size: 1rem;
            svg {
              fill: $primary;
            }
          }

          .star-icon {
            position: absolute;
            margin-left: 5px;
          }

          .icon {
            margin-right: 4px;
            fill: $primary;
          }
        }
      }
    }

    .sidebar-subheader {
      padding: 0 20px;
      margin-top: 1rem;
      margin-bottom: 5px;
      font-weight: bold;
      color: #52b788;
      cursor: default;
      text-transform: uppercase;
      font-size: 0.7rem;
      letter-spacing: 2px;
    }
    .tag-icon {
      display: inline-block;
      margin-right: 3px;
      border-radius: 50%;
      width: 0.8rem;
      height: 0.8rem;
    }

    .trial-box {
      background-color: #d49f4e;
      padding: 4px 8px;
      width: 65%;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 10px 10px 0 0;
      margin: 0 auto;
      font-size: 0.9rem;
      &__link {
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        text-transform: capitalize;
        &:hover {
          text-decoration: none;
          color: #ffffff;
        }
      }

      h5 {
        font-weight: bold;
        color: #ffffff;
        font-size: 0.85rem;
        margin-bottom: 15px;
      }

      .btn {
        align-self: center;
        font-weight: bold;
      }
    }
  }
  .topics-scroll {
    max-height: 400px;
    overflow: auto;
  }
}
.quotes-sidebar-slide-enter-active {
  animation: quotes-sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.quotes-sidebar-slide-leave-active {
  animation: quotes-sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes quotes-sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes quotes-sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
