<template>
  <transition name="video-sidebar-slide">
    <b-col cols="2" class="video-sidebar-container">
      <div class="sidebar col-2">
        <div class="side-nav-wrap">
          <div class="text-center mt-3 mb-5">
            <router-link :to="{ name: 'dashboard' }" class="back-btn w-100 d-block">
              <svg-icon name="dashboard" size="sm" class="mx-1" />
              Back to Dashboard
            </router-link>
          </div>
          <ul class="side-nav mt-4">
            <li>
              <router-link :to="{ name: 'find.videos' }" active-class="active" exact>
                <svg-icon name="popular" />
                Popular
              </router-link>
            </li>
          </ul>
          <div class="sidebar-subheader mt-2">
            Currated Topics
          </div>
          <simplebar class="topics-scroll">
            <ul v-if="hasTopics" class="side-nav">
              <li v-for="topic in curatedTopics" :key="topic.id">
                <router-link
                  :to="{ query: { q: topic.title, platform: $route.query.platform || 'youtube' } }"
                  :title="topic.title"
                  active-class="active"
                  class="text-truncate"
                  exact
                >
                  <span class="tag-icon" :style="getIconStyle()"></span>
                  {{ topic.title }}
                </router-link>
              </li>
            </ul>
          </simplebar>

          <div class="sidebar-subheader mt-2">
            other content types
          </div>
          <ul class="side-nav">
            <li>
              <router-link :to="{ name: 'find.articles' }" active-class="active">
                <svg-icon name="globe" class="mx-2" />
                Articles
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'find.quotes' }" active-class="active">
                <svg-icon name="quote" class="mx-2" />
                Quotes
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'find.memes' }" active-class="active">
                <svg-icon name="laugh" class="mx-2" />
                Memes
              </router-link>
            </li>
          </ul>
        </div>

        <div class="trial-box">
          <a href="https://support.contentburger.io" target="_blank" class="px-3 py-1 trial-box__link">
            Help center
          </a>
        </div>
      </div>
    </b-col>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'Sidebar',

  components: { simplebar },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      curatedTopics: state => state.topics.curated,
    }),
    hasTopics() {
      return this.curatedTopics && this.curatedTopics.length > 0;
    },
  },

  methods: {
    getIconStyle() {
      let color;
      do {
        color = this.generateColor();
      } while (color === '#ffffff' || color.length < 7);
      return {
        backgroundColor: color,
      };
    },
    generateColor() {
      return '#' + ((Math.random() * 0xffffff) << 0).toString(16);
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.video-sidebar-container {
  position: relative;
  .back-btn {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    background: #40916c;
    color: #e5fff1;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    &:hover {
      text-decoration: none;
    }
  }

  .sidebar {
    position: fixed;
    // top: $navbar-height;
    top: 0;
    // transform: translateY(-90px);
    z-index: 100;
    height: 100%;
    padding-top: 60px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background: $primary;
    background: #fff;
    color: #fff;
    // border-radius: 0 20px 20px 0;
    overflow: visible;

    .side-nav-wrap {
      .simplebar-scrollbar {
        width: 5px;
      }

      .simplebar-scrollbar:before {
        border-radius: 5px;
        background: rgba($primary, 0.7);
      }

      .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
        /* When hovered, remove all transitions from drag handle */
        opacity: 1;
        transition: opacity 0s linear;
      }
    }

    .side-nav {
      list-style: none;
      padding: 0;
      &__divider {
        background: #52b788;
        padding: 0;
        margin: 0 18px 5px 18px;
      }

      li {
        a {
          margin-bottom: 5px;
          display: block;
          padding: 10px 15px;
          color: $primary;
          // font-size: 12px;
          font-weight: 400;
          border-left: 4px solid transparent;
          cursor: pointer;

          &:hover {
            text-decoration: none;
            background-color: rgba($primary, 0.2);
          }
          &.active {
            border-left: 8px solid #42c589;
            box-shadow: 0 0 7px rgba($primary, 0.1);
          }
          &:hover,
          &.active {
            border-radius: 5px;

            // font-size: 13px;
          }

          .star-icon {
            position: absolute;
            margin-left: 5px;
          }

          .icon {
            margin-right: 4px;
          }
        }
      }
    }

    .sidebar-subheader {
      padding: 0 20px;
      margin-top: 1rem;
      margin-bottom: 5px;
      font-weight: bold;
      color: #52b788;
      cursor: default;
      text-transform: uppercase;
      font-size: 0.7rem;
      letter-spacing: 2px;
    }
    .tag-icon {
      display: inline-block;
      margin-right: 3px;
      border-radius: 50%;
      width: 0.8rem;
      height: 0.8rem;
    }

    .trial-box {
      background-color: #d49f4e;
      padding: 4px 8px;
      width: 65%;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-radius: 10px 10px 0 0;
      margin: 0 auto;
      font-size: 0.9rem;
      &__link {
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        text-transform: capitalize;
        &:hover {
          text-decoration: none;
          color: #ffffff;
        }
      }

      h5 {
        font-weight: bold;
        color: #ffffff;
        font-size: 0.85rem;
        margin-bottom: 15px;
      }

      .btn {
        align-self: center;
        font-weight: bold;
      }
    }
  }
  .topics-scroll {
    max-height: 250px;
    overflow: auto;
  }
}
.video-sidebar-slide-enter-active {
  animation: video-sidebar-slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.video-sidebar-slide-leave-active {
  animation: video-sidebar-slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes video-sidebar-slide-right {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes video-sidebar-slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-250px);
  }
}
</style>
