<template>
  <div class="article-skeleton-wrap d-flex flex-wrap">
    <div v-for="item in items" :key="item + 1" class="article-item-skeleten shadow-sm mb-4">
      <skeleton-screen shade="lighter" width="100%" height="150px" class="mb-5 article-item-skeleten-img" />
      <div class="px-5">
        <skeleton-screen shade="lighter" width="70%" class="mb-2" />
        <skeleton-screen shade="lighter" width="95%" class="mb-2" />
        <skeleton-screen shade="lighter" width="85%" class="mb-2" />
        <skeleton-screen shade="lighter" width="95%" class="mb-2" />
        <skeleton-screen shade="lighter" width="97%" class="mb-2" />
        <skeleton-screen shade="lighter" width="100%" class="mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'article-skeleton',

  props: {
    itemCount: {
      type: Number,
      default: 9,
    },
  },

  computed: {
    items() {
      return [...Array(this.itemCount).keys()];
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.article-skeleton-wrap {
  .article-item-skeleten {
    width: 24%;
    height: 400px;
    background-color: $white;
    border-radius: 5px;

    .article-item-skeleten-img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
</style>
